<template>
    <div class="submit-code-route">
        <task-top-bar />
        <b-container class="container-card mt-5">
            <b-row class="top-row justify-content-center pt-4"><span class="title">{{ labelText('mainTitle') }}</span></b-row>
            <b-row class="middle-row">
                <div class="rel">
                    <div class="d-none d-lg-block">
                        <b-row class="width-helper place-icons px-4">
                            <b-col class="pl-5 mr-5">
                                <div class="border border-warning border-2">
                                    <font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" class="pl-2 icon-vector" />
                                    <span class="pl-2 pr-2">{{ points }}</span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="d-lg-none place-icons-2 px-4 ">
                        <b-col>
                            <div class="border border-warning border-2-small px-2 vertical-align">
                                <font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" class="pl-2" />
                                <span class="pl-2 pr-2">{{ points }}</span>
                            </div>
                        </b-col>
                    </div>
                </div>
                <div class="informations">
                    <span id="popover-target-1">
                        {{ labelText('howToTitle') }}
                    </span>
                    <b-popover
                        class="popover-body"
                        triggers="hover"
                        target="popover-target-1"
                        placement="top"
                    >
                        <b-container class="pop-up-backround" fluid>
                            <b>{{ labelText('howToToolTipTitle') }}</b>
                            <p>{{ labelText('howToToolTipBody') }}</p>
                        </b-container>
                    </b-popover>
                </div>
            </b-row>
            <b-row class="bottom-row justify-content-center">
                <div class="mt-5">
                    <span class="bottom-text-1">{{ labelText('promptMessage') }}</span>
                    <b-container>
                        <b-row class="justify-content-md-center error-message">
                            <span v-if="codeEmpty">{{ labelText('emptyError') }}</span>
                            <span v-if="failedResponse.invalid && !codeEmpty">{{ labelText('invalidMessageError') }}</span>
                            <span v-if="failedResponse.alreadyRedeemed && !codeEmpty">{{ labelText('alreadyRedeemedError') }}</span>
                        </b-row>
                        <b-row class="mt-2 vertical-align">
                            <b-col lg="9" class="py-3">
                                <b-form-input id="input-large" v-model="code" size="lg" />
                            </b-col>
                            <b-col lg="3">
                                <button
                                    class="submit-button"
                                    @click="submit"
                                >{{ $t('code.buttonText') }}</button>
                            </b-col>
                        </b-row>
                    </b-container>
                    <p class="bottom-text-2">e.g. NFIQ4M</p>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import TaskTopBar from '@/components/TaskTopBar'
    import inCodeService from '@/api/in-codes'
    import eventBus from '@/utils/event-bus'
    export default {
        name: 'SubmitCode',
        components: {
            TaskTopBar
        },
        mixins: [config],
        data() {
            return {
                failedResponse: {},
                code: null,
                codeEmpty: false,
                points: null,
                successMessage: ''
            }
        },
        computed: {
            inCodeLabels() {
                return this.$store.getters.getInCodeLabels
            }
        },
        methods: {
            labelText(fieldName) {
                return this.inCodeLabels ? this.inCodeLabels[fieldName] : ''
            },
            submit() {
                if (!this.code) {
                    this.codeEmpty = true
                } else {
                    this.codeEmpty = false
                }

                if (this.$store.getters.isPreviewMode) {
                    eventBus.$emit('openSubmitCodeModal', {
                        pointsWon: this.points || 100,
                        successMessage: this.message || 'Congratulations'
                    })
                } else {
                    inCodeService.redeemIncodeAction(this.code).then((res) => {
                        if (res.data.pointsEarned > 0) {
                            eventBus.$emit('openSubmitCodeModal', {
                                pointsWon: res.data.pointsEarned,
                                successMessage: res.data.message || 'Congratulations'
                            })
                            this.$store.dispatch('refreshCurrentUser')
                        } else {
                            this.failedResponse = res.data
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.width-helper {
    width: 350px;
}

.error-message {
    font-weight: 700;
    font-size: 25px;
    color: var(--error);
}

.container-card {
    max-width: 700px;
    min-width: auto;
    box-shadow: 0 0 20px -3px var(--black);
    border-radius: 30px;
}

.top-row {
    background: var(--white);
    min-height: 106px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.middle-row {
    background-image: url("~@/assets/images/insert-code/insert-code-icon.png");
    height: 277px;
    position: relative;
}

.title {
    font-weight: 700;
    color: var(--primary-color);
    font-size: 30px;
    padding-bottom: 20px;
}

.place-icons {
    margin-top: 65px;
    margin-left: 300px;
}

.place-icons-2 {
    margin-top: 150px;
}

.border-2 {
    border-width: 2px !important;
    border-color: var(--secondary-color) !important;
    background: var(--white);
    color: var(--secondary-color);
    border-radius: 10px;
    font-weight: 700;
    font-size: 49px;
    box-shadow: 0 0 10px -3px var(--black);
}

.border-2-small {
    border-width: 2px !important;
    border-color: var(--secondary-color) !important;
    background: var(--white);
    color: var(--secondary-color);
    border-radius: 10px;
    font-weight: 700;
    font-size: 25px;
    box-shadow: 0 0 10px -3px var(--black);
}

.bottom-row {
    background: var(--secondary-color);
    min-height: 240px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.bottom-text-1 {
    font-weight: 700;
    color: var(--white);
    font-size: 28px;
}

.bottom-text-2 {
    color: var(--white);
    font-size: 14px;
}

.informations {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 5px;
    margin-right: 30px;
    font-size: 15px;
    text-decoration-line: underline;
    text-decoration-style: dotted;
}

.icon-vector {
    width: 25px;
}

.icon-vector-small {
    width: 20px;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.pop-up-backround {
    font-size: 14px;
    color: var(--white);
}

.submit-button {
    padding: 10px 25px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 6px;
    color: var(--white);
    border: 0;
    background: var(--primary-color);
    outline: none !important;
    transition: all 0.1s ease-in-out;
}

.submit-button:active {
    transform: scale(0.9);
}

</style>

<style lang="scss">
.popover-body {
    width: auto;
    background-image: url("~@/assets/images/insert-code/popUp.png") !important;
    white-space: normal;
    text-align: center;
}
</style>
